import { f } from 'vue3-observe-visibility';
import { createStore } from 'vuex'
import cookie from 'vue-cookie'
var querystring = require('querystring');
const tokenAuthCrm = cookie.get('crm_auth_token');

export default createStore({
    state: {
        pageSetup: null,
        formSetup: null,
        locationArr: null,
        conceptArr: null,
        innerContentArr: null,
        apartmentsArr: null,
        newsArr: null,
        newsData: null,

        isOpenFormModal: false,
        isOpenModalPromo: false,
        isOpenSendwich: false,
        isOpenFormLandModal: false,
        // flats CRM VARS
        salesCardPage: null,
        salesCardContent: null,
        saleFilterParams: null,
        flatsItems: null,
        flatCard: null,
        flatsFilterData: null,
        flatsPagination: {
            allApartments: 0,
            remainingApartments: 0,
            remainingPages: 0,
        },
        pagin_PageNumber: 1,
        gridView: true,
        flatsEmptyMsg: false,
        objectDataCrm: null,
        visibleFilterMobile: false,
        FilterResetTrigger: false,
        modalData: null,
        isMobileGlobal: null,
        ucoCRM_oauth_token: null,
        gCap_siteKey: '6Le8xs4pAAAAAANE4jEMa3p45bx4I1Z_re1HwofR',
    },
    getters: {
        loadGcap_sitekey: state => state.gCap_siteKey,
        loadIsOpenFormLandModal: state => state.isOpenFormLandModal,
        loadOpenFormModal: state => state.isOpenFormModal,
        loadOpenModalPromo: state => state.isOpenModalPromo,
        loadOpenSendwich: state => state.isOpenSendwich,
        //Get global lang Content Var
        loadPageSetUp: state => state.pageSetup,
        loadFormSetUp: state => state.formSetup,
        loadLocation: state => state.locationArr,
        loadConcept: state => state.conceptArr,
        loadInnerContent: state => state.innerContentArr,
        loadApartments: state => state.apartmentsArr,
        loadNews: state => state.newsArr,
        loadNewsData: state => state.newsData,
        //getters for flats CRM
        loadSalesCardPage: state => state.salesCardPage,
        loadSalesCardContent: state => state.salesCardContent,
        loadSalesFilterCardParams: state => state.saleFilterParams,
        loadFlatsItems: state => state.flatsItems,
        loadFlatCard: state => state.flatCard,
        loadFlatsFilterData: state => state.flatsFilterData,
        loadFlatsPagination: state => state.flatsPagination, // параметры остаточных значений(счетчиков) пагинации
        loadPaginPageNumber: state => state.pagin_PageNumber,
        loadFlatsEmptyMsg: state => state.flatsEmptyMsg,
        loadObjectDataCrm: state => state.objectDataCrm,
        loadVisibleFilterMobile: state => state.visibleFilterMobile,
        loadLandFlatsGridView: state => state.gridView,
        loadSalesLandModalDataArr: state => state.modalData,
        loadResetTriggerMove: state => state.FilterResetTrigger,
        loadisMobileGlobal: state => state.isMobileGlobal,
        loadUcoCRM_auth_token: state => state.ucoCRM_oauth_token,
    },
    actions: {
        installMainLang_Global({commit}, payload) {
            commit('setUp_mainLang', payload);
        },
        clearNewsData({commit}) {
            commit('newsDataClear');
        },
        //// GLOBAL ACTIONS
        getSectionsData({commit}) {
            axios.get(window.url + window.GlobalLang + window.JsonUrl + 'sections').then(async (response) => {
                // console.log(response)
                if (this.state.pageSetup == null) {
                    return new Promise((resolve, reject) => {
                        commit('PageSetUp', response.data[0].acf);
                        commit('Forms', response.data[1].acf);
                        commit('Location', response.data[2].acf);
                        commit('Concept', response.data[3].acf);
                        commit('InnerContent', response.data[4].acf);
                        commit('Apartments', response.data[5].acf);
                        commit('News', response.data[6].acf);
                        resolve('complete Get SetUp');
                    });
                }
            });
        },
        // SALES CARD PROMO/LAND
        getSalesCardPageData({commit}) {
            axios.get(window.SalesURL + window.GlobalLang + window.JsonUrl + 'pages/1661').then(async (response) => {
                // console.log(response.data.acf, 'sale CARD PAGE DATA DEFAULT');
                commit('salesCardPage', response.data.acf);
            });
        },
        // FLATS FROM CRM
        // oauth 2.0 rest api crm test
        getFlatsItems({commit, dispatch, state}, payload) { // FLATS DEFAULT LOAD
            if (this.state.flatsItems == null) {
                return new Promise((resolve, reject) => {
                    axios.post(window.CRMurl + '/rest/prices/getApartments', payload, {
                        headers: { 
                            'Authorization' :  `Bearer ${state.ucoCRM_oauth_token}`,
                        } 
                    }).then((response) => {
                        // console.log(response, response.data.data, 'apartments after AUTH');
                        commit('flatsItemsArr', response.data.data);
                        resolve('complete FLATS');
                    }).catch((error) => {
                        console.log('error getApartments default', error.response.data, error.response.status);
                    });
                });
            }
        },
        getFlatsByFilter({commit, dispatch, state}, payload) { // FLATS LOAD BY FILTER
            // console.log(payload)
            axios.post(window.CRMurl + '/rest/prices/getApartments', payload, {
                headers: { 
                    'Authorization' :  `Bearer ${state.ucoCRM_oauth_token}`,
                } 
            }).then((response) => {
                // console.log(response, response.data.data, 'FILTER APPS after AUTH');
                commit('flatsItemsArr', response.data.data);
            }).catch((error) => {
                console.log('error getApartments filter', error.response.data, error.response.status);
            });
        },
        getFlatsByPagination({commit, dispatch, state}, payload) { // FLATS LOAD BY pagination with filter and pagePaginNumber params
            // console.log(payload)
            axios.post(window.CRMurl + '/rest/prices/getApartments', payload, {
                headers: { 
                    'Authorization' :  `Bearer ${state.ucoCRM_oauth_token}`,
                } 
            }).then((response) => {
                // console.log(response, response.data.data, 'pagination after AUTH');
                commit('flatsItemsArrPagination', response.data.data);
            }).catch((error) => {
                console.log('error getApartments pagination', error.response.data, error.response.status);
            });
        },
        getFlatsPagintationCounters({commit, dispatch, state}, payload) {
            axios.post(window.CRMurl + '/rest/prices/getApartmentsCount', payload, {
                headers: { 
                    'Authorization' :  `Bearer ${state.ucoCRM_oauth_token}`,
                } 
            }).then((response) => {
                // console.log(response, response.data.data, 'paginationCounter after AUTH');
                commit('flatsPaginationCountersSet', response.data.data);
            }).catch((error) => {
                console.log('error getApartments pagination Counter', error.response.data, error.response.status);
            });
        },
        getCrmAuthToken({commit}, payload) { // Получение Токена из СРМ системы для oauth 2.0 в необходимых для авторизации запросов
            return new Promise((resolve, reject) => {
                axios.post('https://admin.sales.bazis.kz/server/new-auth.php', querystring.stringify({'secret_key' : '0L3QtSDRgdGD0LnRgtC1INGB0LLQvtC5INC90L7RgQ=='})).then((response) => {
                    if (response) {
                        var tokenParse = JSON.parse(response.data.token);
                        var token = tokenParse.access_token;
                        commit('setOauthToken_from_server', token);
                        resolve(true);
                        // console.log(token,'- token');
                    } else {
                        resolve(false);
                    }
                }).catch((error) => {
                    console.log('error ' + error, 'erorr Get Token');
                    resolve(false);
                });
            });
        },
        // getCrmAuthToken({commit}, payload) { // Получение Токена из СРМ системы для oauth 2.0 в необходимых для авторизации запросов
        //     axios.post('https://sales.bazis.kz/server/auth.php', querystring.stringify({'secret_key' : '0L3QtSDRgdGD0LnRgtC1INGB0LLQvtC5INC90L7RgQ=='})).then((response) => {
        //         cookie.set('crm_auth_token', response.data.access_token, { expires: response.data.expires_in + 's' });
        //         if (payload == 'after_error_token_invalid') {
        //             location.reload();
        //         }
        //     }).catch((error) => {
        //         console.log('error ' + error, 'erorr Get Token');
        //     });
        // },
    },
    mutations: {
        setOauthToken_from_server(state, data) {
            state.ucoCRM_oauth_token = data;
        },
        Open_form_modal(state, data) {
            state.isOpenFormModal = data
        },
        Open_promo_modal(state, data) {
            state.isOpenModalPromo = data
        },
        Open_Sendwich(state, data) {
            state.isOpenSendwich = data
        },
        //
        PageSetUp(state, data) {
            state.pageSetup = data
        },
        Forms(state, data) {
            state.formSetup = data
        },
        Location(state, data) {
            state.locationArr = data
        },
        Concept(state, data) {
            state.conceptArr = data
        },
        InnerContent(state, data) {
            state.innerContentArr = data
        },
        Apartments(state, data) {
            state.apartmentsArr = data
        },
        News(state, data) {
            state.newsArr = data
        },
        //////////// CRM BAZIS FLATS MUTATIONS
        salesCardPage(state, data) {
            state.salesCardPage = data;
        },
        salesCardContent(state, data) {
            state.salesCardContent = data;
        },
        FilterSaleItemCard_Set(state, data) {
            state.saleFilterParams = data
        },
        flatsItemsArr(state, data) {
            // console.log(data, 'test')
            state.flatsItems = data;
            if (data.length == 0) {
                state.flatsEmptyMsg = true
            } else {
                state.flatsEmptyMsg = false
            }
        },
        flatsItemsArrPagination(state, data) {
            // console.log(data, 'PAGINATION MUTATION');
            for (var i = 0; i < data.length; i++) {
                state.flatsItems.push(data[i]);
            }
            // console.log(state.flatsItems);
        },
        flatsPaginationCountersSet(state, data) {
            state.flatsPagination.allApartments = data.allApartments;
            state.flatsPagination.remainingApartments = data.remainingApartments;
            state.flatsPagination.remainingPages = data.remainingPages;
        },
        setObjectDataCRM(state, data) {
            state.objectDataCrm = data[0];
        },
        setFlatsFilterData(state, data) {
            state.flatsFilterData = data
        },
        UpdatePaginPageNumber(state, data) {
            state.pagin_PageNumber = data
        },
        // mutations for news section
        newsData(state, data) {
            state.newsData.push(data);
            console.log(state.newsData, 'asdasdsad')
        },
        newsDataClear(state, data) {
            state.newsData = []
        },
        // for flats
        SetVisibleFilterMobile(state, data) {
            state.visibleFilterMobile = data
        },
        TriggerResetFilterMove(state, data) {
            state.FilterResetTrigger = data
        },
        gridView(state, data) {
            state.gridView = data;
        },
        modalData(state, data) {
            state.modalData = data
        },
        isMobileGlobalSet( state, data) {
            state.isMobileGlobal = data
        },
        Open_FormLandModal(state, data) {
            state.isOpenFormLandModal = data
        },
    },
})
